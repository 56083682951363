body {
  margin: 0;
  /* font-family: 'Mada', sans-serif !important; */
  font-family: 'Poppins', sans-serif;
  background-color: #EEEFF0;
  font-size: 16px;
}

#root{
	background: white;
}

/* .weex_container { 
  width: 85vw;
  min-width: 85vw;
} */

.label {
  /* font-family: 'Mada', sans-serif !important; */
  font-family: 'Poppins', sans-serif !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

@keyframes satisfacao {
  0% {
    padding: 1.0em;
    
    
  }

  50% {
    padding: 1.5em;
    
  }

  100% {
    padding: 1.0em;
    
  }
}


